/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
.component-image-grid {
  display: flex;
  flex-wrap: wrap;
}

.component-image-grid figure:before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, #000000 100%);
  content: '';
  width: 100%;
  left: 0;
  right: 0;
  height: 100%;
  position: absolute;
  display: block;
  opacity: 0.6;
  z-index: 1;
}

.component-image-grid figure:after {
  content: '';
  background: transparent;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
}

.component-image-grid figure img {
  pointer-events: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.component-image-grid figure,
.component-image-grid .scLooseFrameZone {
  flex-basis: 30%;
  flex-grow: 1;
  border: 0.5px solid white;
  position: relative;
  margin: 0;
  overflow: hidden;
  min-width: 350px;
  max-width: 50%;
}

.component-image-grid figure figure,
.component-image-grid .scLooseFrameZone figure {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

@media (max-width: 1199px) {
  .component-image-grid figure,
  .component-image-grid .scLooseFrameZone {
    max-width: 100%;
  }
}

.component-image-grid figure a,
.component-image-grid .scLooseFrameZone a {
  display: block;
  font-size: 1em;
  width: 100%;
  height: 100%;
}

.component-image-grid figure a:hover img,
.component-image-grid .scLooseFrameZone a:hover img {
  transform: scale(1.1);
}

.component-image-grid figure img,
.component-image-grid .scLooseFrameZone img {
  vertical-align: bottom;
  width: 100%;
  transition: transform .8s;
  object-fit: cover;
}

.component-image-grid figure figcaption,
.component-image-grid .scLooseFrameZone figcaption {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: 2em;
  color: white;
  padding: 0.5em 1em;
  letter-spacing: 0.2px;
  line-height: 1.875em;
  z-index: 2;
}

.component-image-grid.count-4 figure {
  flex-basis: 50%;
}

.image-grid .component-content,
.image-grid .container,
.image-grid .component {
  width: 100%;
}
